import Text from '@atoms/Text';
import texts from '@components/Homepage/en.json';
import Image from '@atoms/Image';
import ExploreOpportunitiesButton from '../ExploreOpportunitiesButton';
import DiscoverBonds from './DiscoverBonds';
import ParallaxComponent from './ParallaxComponent';
import Lottie from 'lottie-react';
import sebiAnimation from '../../../public/assets/sebiAnimation.json';
import { BannerData } from '@helpers/homepageData';
import MuhuratDaysOfferText from './MuhuratDaysOfferText';

const Banner = ({ onButtonClick, showMuhuratDays, ctaButtonText='' }) => {
    return (
        <div
            className="page-wrapper relative w-full lg:min-h-screen flex flex-col justify-center pb-12 md:pb-14 lg:pb-7 bg-[url(/images/homepage/background-gradient-1.svg)] bg-cover bg-top bg-no-repeat">
            <Image
                src="/images/background/jiraaf-light-gray-bg-left-mobile.svg"
                className="xl:hidden absolute md:z-0 left-4 top-24 scale-[3] md:scale-[2.5] md:-left-[22rem] md:-top-[33rem] md:-rotate-[12deg] lg:-rotate-[16deg] xl:-left-[22rem] xl:-top-[33rem]"
            />
            <Image
                src="/images/CreditEvaluationProcess/jiraaf-light-gray-bg-left.png"
                className="hidden xl:block absolute z-0 top-0  lg:scale-[1.5] lg:-top-[13rem] lg:left-[230px] rotate-[0deg]"
            />
            <div
                className="hidden md:block absolute z-20 md:right-0 lg:-right-10 xl:right-[64px] md:top-32 lg:top-28 h-full xl:top-20 maxScreen:top-16">
                <ParallaxComponent />
            </div>
            {showMuhuratDays ? <div className='max-w-[350px] xl:max-w-[387px] mt-6 xl:mt-12 mx-auto xl:mx-0 md:hidden'><MuhuratDaysOfferText /></div>: null}
            <div
                className="md:inline-flex hidden  md:max-w-[376px] lg:max-w-none lg:w-[736px] flex-col justify-start gap-12   items-center md:items-start relative mx-4 md:mx-6 md:mr-0 lg:mx-20 maxScreen:ml-24 pt-[161px] md:pt-[72px] lg:pt-0 lg:my-auto z-2">
                <div className="flex flex-col items-start justify-start gap-6">
                    <div className="flex flex-col items-start self-stretch justify-start gap-8">
                        <div className="flex flex-col items-start self-stretch justify-start gap-8 lg:gap-10">
                            <div className="relative inline-flex items-center justify-start">
                                <div className="absolute w-16 h-16 -left-5 -top-[22px]">
                                    <Lottie
                                        animationData={sebiAnimation}
                                        loop={false}
                                        autoplay={true}
                                        className="w-full h-full"
                                    />
                                </div>
                                <Text
                                    content={'Regulated by SEBI'}
                                    className="text-gray-600 ml-7 p4-semibold"
                                />
                            </div>
                            <div className="flex flex-col items-start self-stretch justify-start xl:gap-1">
                                <Text
                                    content={texts.HomepageMainHeader}
                                    className="text-gray-500 p3-regular lg:h4-regular !font-montserrat"
                                />
                                <Text
                                    content={texts.HomepageSubHeader}
                                    className="h2-regular lg:d3-regular text-primary-500"
                                    isInnerHtml={true}
                                />
                            </div>
                        </div>
                        <div className={`order-2 md:order-3 ${showMuhuratDays ? 'flex lg:flex-row flex-col lg:items-center gap-4' : ''}`}>
                            <ExploreOpportunitiesButton
                                className="!my-0"
                                onButtonClick={onButtonClick}
                                ctaButtonText={ctaButtonText}
                            />
                            {showMuhuratDays ? <MuhuratDaysOfferText/> : null}
                        </div>
                    </div>
                </div>
                <div className="inline-flex flex-wrap items-start justify-start w-full gap-12 lg:gap-10">
                    {BannerData?.map(
                        ({ image, header, description }, index) => (
                            <div
                                key={index}
                                className="inline-flex flex-col items-start justify-start lg:flex-1 gap-2 px-0 w-[148px] lg:min-w-min"
                            >
                                <div className="relative w-8 h-8">
                                    <Image
                                        src={image}
                                        className="w-full h-full"
                                    />
                                </div>

                                <div className="flex flex-col items-start justify-center">
                                    <Text
                                        content={header}
                                        className="text-center p4-semibold text-primary-500"
                                    />
                                    <Text
                                        content={description}
                                        className="p4-regular text-basicBlack "
                                    />
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div
                className={`flex flex-col items-center md:items-start md:hidden relative px-4 md:px-6 lg:px-16 maxScreen:px-24 pt-[161px] md:pt-[72px] lg:pt-0 lg:my-auto z-20`}>
                <Text
                    content={texts?.HomepageHeader}
                    className="order-1 hidden md:block z-20 md:h3-semibold lg:h2-semibold text-primary-500 md:max-w-[55%] lg:max-w-[624px]"
                />
                <div
                    className="relative flex-col order-1 rounded-lg py-7 md:hidden bg-basicWhite px-9 flex-center shadow-home-page-banner-card-2">
                    <Text
                        content={texts.HomepageMainHeader}
                        className="text-gray-500 p4-regular !font-montserrat"
                    />
                    <Text
                        content={texts.HomepageSubHeader}
                        className="text-center h4-semibold text-primary-500"
                        isInnerHtml={true}
                    />
                    <Image
                        src="/images/coin-homepage.png"
                        className="absolute left-2 -bottom-5 w-[56px] h-[42px] coin"
                    />
                    <Image
                        src="/images/homepage/jiraaf-mascot-2.webp"
                        className="absolute -top-[138px] w-[170px]"
                    />
                </div>

                <div className="order-3 md:order-2 md:min-w-[559px] lg:min-w-[620px] mt-10">
                    <DiscoverBonds />
                </div>
                <div className="flex flex-col items-center justify-center order-2 mt-6 md:order-3">
                    <div className="relative inline-flex items-center justify-start">
                        <div className="absolute w-16 h-16 -left-5 -top-[22px]">
                            <Lottie
                                animationData={sebiAnimation}
                                loop={false}
                                autoplay={true}
                                className="w-full h-full"
                            />
                        </div>
                        <Text
                            content={'Regulated by SEBI'}
                            className="text-gray-600 ml-7 p4-semibold"
                        />
                    </div>
                    <ExploreOpportunitiesButton
                        className="mt-8 md:my-0"
                        onButtonClick={onButtonClick}
                        ctaButtonText={ctaButtonText}
                    />
                </div>
            </div>
        </div>
    );
};

export default Banner;